import { EDHRECIconsType } from '@/components/Icon/Icon'

export const desktopWidth = 1024

export type Mode = {
  icon?: EDHRECIconsType
  key: string
  skipFetch?: boolean
  value: string
}

export const defaultGroupedBy = 'default'

export const defaultSortedBy = 'default'

export const groupByModes: Mode[] = [
  { icon: 'tableList', key: 'default', skipFetch: true, value: 'Category' },
  { icon: 'tag', key: 'type', value: 'Type' },
  { icon: 'arrowDown19', key: 'mana', value: 'Mana Value' },
  { icon: 'dollarSign', key: 'price', value: 'Price' },
  { icon: 'star', key: 'rarity', value: 'Rarity' },
  { icon: 'calendar', key: 'date', value: 'Release date' },
  { icon: 'tableCellsLarge', key: 'ungrouped', skipFetch: true, value: 'Ungrouped' },
]

export const sortByModes: Mode[] = [
  { key: 'default', skipFetch: true, value: 'Default' },
  { icon: 'percent', key: 'percent', skipFetch: true, value: 'Inclusion' },
  { icon: 'hashtag', key: 'count', skipFetch: true, value: 'Deck Count' },
  { icon: 'wandMagicSparkles', key: 'synergy', skipFetch: true, value: 'Synergy' },
  { icon: 'font', key: 'name', skipFetch: true, value: 'Name' },
  { icon: 'arrowDown19', key: 'mana', value: 'Mana Value' },
  { icon: 'dollarSign', key: 'price', value: 'Price' },
  { key: 'salt', value: 'Salt' },
]

export const recaptchaKey = '6Lce4KcUAAAAABGKupP_l1JmBkkDNj39TOOU3ulX'

export const BORDER_WIDTH = 1
export const CARD_WIDTH = 244
export const GRID_GAP = 8

export const TIMESPANS = [undefined, 'week', 'month', 'year']

export const COLORS = {
  b: 'Mono-Black',
  bg: 'Golgari',
  bgu: 'Sultai',
  br: 'Rakdos',
  brg: 'Jund',
  brgw: 'Dune-Brood',
  colorless: 'Colorless',
  g: 'Mono-Green',
  gu: 'Simic',
  gur: 'Temur',
  gw: 'Selesnya',
  gwu: 'Bant',
  gwub: 'Witch-Maw',
  r: 'Mono-Red',
  rg: 'Gruul',
  rgw: 'Naya',
  rgwu: 'Ink-Treader',
  rw: 'Boros',
  rwb: 'Mardu',
  u: 'Mono-Blue',
  ub: 'Dimir',
  ubr: 'Grixis',
  ubrg: 'Glint-Eye',
  ur: 'Izzet',
  urw: 'Jeskai',
  w: 'Mono-White',
  wb: 'Orzhov',
  wbg: 'Abzan',
  wu: 'Azorius',
  wub: 'Esper',
  wubr: 'Yore-Tiller',
  wubrg: 'Five-Color',
}
