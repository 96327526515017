import Cookies from 'universal-cookie'

const expirationDurations = {
  hour: 3600,
  day: 86400,
  week: 604800,
  month: 2592000,
  year: 31536000,
}

const get = <T = any>(path: string, serverCookies?: Record<string, any> | string): T => {
  const cookies = new Cookies(serverCookies)
  const result = cookies.get(path)
  return result as T
}

const getJSON = <T = any>(path: string, serverCookies?: Record<string, any> | string): T =>
  JSON.parse(get(path, serverCookies)) as T

const set = (key: string, value: any, age?: keyof typeof expirationDurations) => {
  const cookies = new Cookies()
  const maxAge = age ? expirationDurations[age] : undefined
  cookies.set(key, value, { path: '/', maxAge, httpOnly: false })
}

const setJSON = (key: string, value: Record<string, any>, age?: keyof typeof expirationDurations) =>
  set(key, JSON.stringify(value), age)

const remove = (key: string) => {
  const cookies = new Cookies()
  cookies.remove(key, { path: '/' })
}

const cookieService = {
  get,
  getJSON,
  set,
  setJSON,
  remove,
}

export default cookieService
