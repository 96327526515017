import { createContext, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

type ThemeContextType = [string, (theme: string) => void]

const initialState: ThemeContextType = [
  'light',
  (_theme: string) => {
    return
  },
]

const Context = createContext(initialState as ThemeContextType)

type Props = {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const [cookies, setCookies] = useCookies(['theme'])
  const setTheme = (theme: string) => setCookies('theme', theme)

  useEffect(() => {
    const isLegacyDarkMode = localStorage.getItem('darkMode')

    if (cookies.theme === undefined) {
      if (isLegacyDarkMode !== null) setTheme(isLegacyDarkMode === 'true' ? 'dark' : 'light')
      else {
        const isOsDarkMode = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
        setTheme(isOsDarkMode ? 'dark' : 'light')
      }
    }
  }, [])

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-bs-theme', cookies.theme)
  }, [cookies.theme])

  return <Context.Provider value={[cookies.theme, setTheme]}>{children}</Context.Provider>
}

export const useTheme = (): ThemeContextType => useContext(Context)
export default ThemeProvider
