import { RequestService } from './request.service'

const tags = [
  { id: 'draw', name: 'Card Draw' },
  { id: 'protection', name: 'Protection' },
  { id: 'ramp', name: 'Ramp' },
  { id: 'recursion', name: 'Recursion' },
  { id: 'removal', name: 'Removal' },
  { id: 'sweeper', name: 'Board Wipe' },
  { id: 'tutor', name: 'Tutors' },
]

export class TypeaheadService extends RequestService {
  public getCommanders = (): Promise<Response> =>
    super.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/typeahead/commanders`, {}, true)

  public getCards = (): Promise<Response> =>
    super.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/typeahead`, {}, true)

  public getPartners = (): Promise<Response> =>
    super.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/typeahead/partners`, {}, true)

  public getSets = (): Promise<Response> =>
    super.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/typeahead/sets`, {}, true)

  public getTags = () => Promise.resolve(tags)

  public getFromQuery = (query: string, signal?: AbortSignal): Promise<Response> =>
    super.get(`/api/typeahead?q=${query}`, { signal: signal }, true)
}

const typeaheadService = new TypeaheadService()

export default typeaheadService
