import Script from 'next/script'
import { useEffect, useState } from 'react'
import { Persistor } from 'redux-persist/es/types'

import Toasts from '@/components/Toasts/Toasts'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'

type Props = {
  children: React.ReactNode
  persistor: Persistor
}

const Layout = ({ children, persistor }: Props) => {
  const { query } = useDirectories()
  const patreonTier = usePatreonTier()
  const [prevPatreonTier, setPrevPatreonTier] = useState(patreonTier)

  const loadAds = !patreonTier || !!query?.ads

  // Reload page when user gains Patreon tier
  useEffect(() => {
    if (patreonTier && prevPatreonTier === undefined) {
      if (window.location.pathname === '/signin') {
        window.location.replace('/')
      } else {
        window.location.reload()
      }
    }
    setPrevPatreonTier(patreonTier)
  }, [patreonTier])

  return (
    <>
      <header>
        <Navbar persistor={persistor} />
        {loadAds && (
          <Script
            async
            data-cfasync='false'
            data-noptimize='1'
            src='//scripts.mediavine.com/tags/edhrec.js'
            type='text/javascript'
          />
        )}
      </header>
      {children}
      <Toasts />
      <Footer />
    </>
  )
}

export default Layout
