import { onAuthStateChanged } from '@firebase/auth'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { auth, firestore } from '@/services/firebase.service'
import { SET_USER_STATE } from '@/store/user/actions/types'

let unsub: Unsubscribe | undefined

const FirebaseAuth = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        unsub = onSnapshot(doc(firestore, 'users', user.uid), (doc) => {
          const data = doc.data()

          // Use subobject reference to avoid stale callback
          dispatch({
            type: SET_USER_STATE,
            payload: {
              uid: user.uid,
              ...data,
            },
          })
        })
      } else if (unsub) {
        unsub()
      }
    })
  }, [])

  return null
}

export default FirebaseAuth
